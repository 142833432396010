const api = {
  // 登录注册相关
  common: {
    POST_LOGIN: "/supply/right/login/login",//登陆
    CHECK_ACCOUNT: "/supply/right/login/forgetPwdStep1",//忘记密码第一步
    SET_NEW_PASSWORD: "/supply/right/login/forgetPwdStep2",//忘记密码第二步
    POST_REGISTER: "/supply/right/register/register",//注册
    POST_LOGOUT: "/supply/right/login/logout",//退出
    GET_GENERATE_IMAGE_CODE: "/supply/right/login/generateImageCode",//获取验证码

    POST_LOCATION_BY_PARENT: "/basic/area/areas",//根据父id查询地区
    GET_EXPRESS_LIST: "/basic/express/list",//物流公司列表
    GET_EXPRESS_DETAIL: "/basic/express/queryLogisticByExpressNo",//获取物流详情信息
    POST_UPLOAD_FILE: "/basic/file/upload",//上传单个文件
    POST_UPLOAD_FILES: "/basic/file/batchUpload",//上传多个文件

  },
  //商品相关
  goods: {
    //供应商商品
    POST_ADD_SUPPLIER_GOODS: "/supply/product/goods/add",//新增供应商商品
    POST_EDIT_SUPPLIER_GOODS: "/supply/product/goods/edit",//编辑供应商商品
    POST_GET_SUPPLIER_GOODS_INFO: "/supply/product/goods/getGoodsInfo",//获取供应商商品信息
    POST_SUPPLIER_GOODS_LIST: "/supply/product/search/goodsSearch",//获取供应商商品列表
    POST_SUPPLIER_GOODS_OFFSHELF: "/supply/product/goods/offShelf",//单商品下架
    POST_SUPPLIER_GOODS_ONLINESHELF: "/supply/product/goods/onlineShelf",//单商品上架
    POST_SUPPLIER_GOODS_DETAIL: "/supply/product/goods/preview",//商品详情
    POST_GOODS_BRAND_LIST: "/supply/product/brand/list",//获取商品品牌列表
    POST_GOODS_BRAND: "/supply/product/brand/get",//通过brandId获取品牌
    POST_GOODS_CATEGORY_SEARCH: "/supply/product/category/search",//商品分类搜索
    POST_GOODS_CATEGORY: "/supply/product/category/get",//通过id查找category对象
    POST_GOODS_CATEGORY_LIST: "/supply/product/category/getList",//通过parentId查询商品分类

    POST_MODIFY_APPLY_LIST: "/supply/product/goodsChange/getList",//商品变更列表
    POST_MODIFY_APPLY_DETAIL: "/supply/product/goodsChange/getInfo",//商品变更详情
    POST_MODIFY_APPLY: "/supply/product/goodsChange/apply",//商品变更申请
    POST_CHECK_GOODS_IN_ACTIVITY:"/supply/product/goods/isJoinActivity",//判断商品是否参加活动

    POST_GET_PICK_GOODS_LIST: "/supply/activity/sample/goods/searchPurchaseGoods",//获取派样挑选商品列表


    //京东api
    GET_JD_CACHE_STOCK_AREA: "/supply/jd/listStockArea",//缓存库查询城市
    GET_JD_PRODUCT_LIST: "/supply/jd/listSelectProduct",//查询京东待挑选商品信息
    GET_JD_CATEGORY_LIST: "/supply/jd/listCategory",//京东分类查询
    GET_JD_AREA_LIST: "/supply/jd/listArea",//京东地区查询
    GET_JD_PRODUCT_DETAIL: "/supply/jd/getProductDetails",//京东商品详情查询
    GET_JD_MARKET_PRICE: "/supply/jd/getMarketPrice",//实时爬取京东商品市场价
    GET_JD_PRODUCT_INFO: "/supply/jd/getJdProduct",//获取京东商品信息
    POST_JD_PICK_GOODS: "/supply/product/standard/jd",//挑选京东商品

    // 资源库
    POST_GET_RESOURCE_LIST: "/supply/product/spu/getListByPage",//获取资源库列表
    POST_GET_RESOURCE_PRODUCT_INFO: "/supply/product/spu/getInfo",//获取资源库产品详情
    POST_GET_RESOURCE_PRODUCT_BASIC_INFO: "/supply/product/spu/getEasyInfo",//获取资源库产品基本详情
    POST_EDIT_RESOURCE_PRODUCT_BASIC_INFO: "/supply/product/spu/edit",//编辑资源库产品基本详情
    POST_UP_SHELF_RESOURCE_PRODUCT:"/supply/product/spu/shelves",//上架资源库商品



    GET_JD_STORE_LIST: "/supply/product/goods/search/getJdListChoose",
    GET_ELESTORE_JD_LIST: "/supply/product/goods/search/getJdListShop",
    GET_JD_STOCKS: "/supply/product/third/searchStock",
    GET_NEW_MARKET_PRICE: "/supply/jd/getMarketPrice",
    GET_NEW_MARKET_PRICE_MUL: "/supply/jd/syncMarketPrice",
    GET_ADDRESS: "/basic/area/areas",
    GET_JD_PRICE_RULE: "/supply/product/standard/jd",
    GET_SELF_PRICE_RULE: "/supply/product/goods/pick/getSelfToShopEcho",
    SEND_JD_PRICE_RULE: "/supply/product/standard/jd",
    SEND_SELF_PRICE_RULE: "/supply/product/goods/pick/selfToShopBatch",
    GET_CATE_TREE: "/supply/product/category/getTreeList",
    SET_PRODUCT_ONSALE: "/supply/product/pool/goods/onlineShelfBatch",
    SET_PRODUCT_OFFSALE: "/supply/product/pool/goods/offShelfBatch",
    DEL_PRODUCT: "/supply/product/pool/goods/removeBatch",
    REJECT_PRODUCT: "/supply/product/pool/rejectBatch",


  },
  // 运费模板相关
  freight: {
    GET_FREIGHT_AREA: "/basic/dict/dictionaries",//获取运费模板目的地区域
    GET_IS_REPEAT_NAME: "/basic/freight-template/check-name",//判断运费模版名称是否重复
    GET_FREIGHT_DETAIL: "/basic/freight-template/get",//通过id获取运费模板详情
    GET_FREIGHT_TEMPS: "/basic/freight-template/templates",//查询所有运费模板
    GET_SIMPLE_FREIGHT_TEMPS: "/basic/freight-template/templates-simple",//查询所有运费模板（下拉）
    POST_SAVE_FREIGHT_TEMP: "/basic/freight-template/save",//保存运费模板
    POST_UPDATE_FREIGHT_TEMP: "/basic/freight-template/update",//更新运费模板
    DELETE_FREIGHT_TEMP: "/basic/freight-template",//删除运费模板
    POST_FREIGHT_GOODS_LIST: "/supply/product/search/goodsSearchByFreightTemplateId",//运费模板商品列表
    POST_CHANGE_FREIGHT_TEMP: "/supply/product/goods/changeFreightTemplate",//商品运费模板变更
  },
  // 订单相关
  order: {
    POST_ORDER_LIST: "/supply/order/listByPage",//获取订单列表
    GET_ORDER_DETAIL: "/supply/order/getSupplierOrderDetails",//获取子订单详情
    GET_PARENT_ORDER_DETAIL: "/supply/order/getSupplierOrderDetailsByParentOrderNo",//获取父订单详情
    GET_ORDER_SHIPMENT_INFO: "/supply/order/listDeliveryInfo",//发货弹窗信息
    POST_SHIPMENT_GOODS: "/supply/order/deliverGoods",//商品发货

  },
  // 结算相关
  finance: {
    GET_OVERVIEW_DATA: "/supply/balance/bill/queryAmount",//获取概览数据
    GET_MANAGE_BASE_DATA: "/supply/balance/bill/queryAvailableMoney",//结算管理头部信息
    GET_SETTLEMENT_RECORD_LIST: "/supply/balance/bill/queryPageBySupplierId",//分页结算记录
    POST_DEAL_DETAIL_DATA: "/supply/balance/bill/querySupplierTradeAmount",//交易明细统计数据
    POST_DEAL_DETAIL_LIST: "/supply/balance/bill/querySupplierTrade",//交易明细列表

    GET_SETTLEMENT_ACCOUNT_LIST: "/supply/balance/account/query",//结算账户列表
    GET_COMPANY_INFO: "/supply/balance/bill/querySupplierInfo",//企业信息
    POST_ADD_SETTLEMENT_ACCOUNT: "/supply/balance/account/create",//新建结算账户
    POST_DEL_SETTLEMENT_ACCOUNT: "/supply/balance/account/delete",//删除结算账户
    POST_UPDATE_SETTLEMENT_ACCOUNT: "/supply/balance/account/update",//更新结算账户

    GET_SETTLEMENT_BASIC_INFO: "/supply/balance/bill/queryAvailableMoney",//结算发起获取结算基础信息
    GET_RECORD_DETAIL_LIST: "/supply/balance/bill/queryAvailableMergedItems",//查询可以结算开票明细(合并过的商品详情)
    GET_ORDER_DETAIL_LIST: "/supply/balance/bill/queryAvailableOrders",//查询可以结算原始订单明细(未合并的原始商品详情)
    GET_EXPORT_DETAIL: "/supply/balance/bill/exportExcelBeforeBalance",//导出明细
    GET_EXPORT_ORDER: "/supply/balance/bill/exportOrderExcelBeforeBalance",//导出订单
    GET_DETAIL_RECORD_DETAIL_LIST: "/supply/balance/bill/queryDetailByBalanceId",//结算发起开票明细(合并过的商品详情)
    GET_DETAIL_ORDER_DETAIL_LIST: "/supply/balance/bill/queryAssociatedOrderByBalanceId",//结算发起订单明细(未合并的原始商品详情)
    GET_DETAIL_EXPORT_DETAIL: "/supply/balance/bill/exportBalanceExcel",//导出明细(详情)
    GET_DETAIL_EXPORT_ORDER: "/supply/balance/bill/exportOrderExcel",//导出订单(详情)
    POST_APPLY_SETTLEMENT: "/supply/balance/bill/applyBill",//申请结算
    GET_INVOICE_INFO: "/supply/balance/invoice/queryInvoiceAndReceiver",//查询飞象开票信息和收件人信息Invoice(这个接口运营端和供应商共用)
    POST_CANCEL_SETTLEMENT: "/supply/balance/bill/cancelBill",//取消结算
    POST_SUBMIT_SETTLEMENT: "/supply/balance/bill/updateBill",//提交结算
    GET_ONE_SETTLEMENT_INFO: "/supply/balance/bill/queryBill",//获取单个结算单信息

  },
  // 活动相关
  activity: {
    // 派样活动
    POST_GET_SIGN_UP_LIST: "/supply/marketing/activity/sample/applying-list",//获取报名中列表
    GET_SIGN_UP_RECORD_LIST: "/supply/activity/sample/goods/listByPage",//获取报名记录列表
    POST_GET_USER_INFO_LIST: "/supply/marketing/activity/sample/listByPage",//用户资料列表
    GET_ACTIVITY_INFO: "/supply/marketing/activity/sample/getDetail",//获取派样活动详情
    POST_PRESENT_ACTIVITY: "/supply/activity/sample/goods/report",//提报活动
    GET_DOWNLOAD_USER_INFO: "/supply/marketing/activity/sample/export",//导出用户资料
    POST_EXIT_ACTIVITY:"/supply/activity/sample/goods/applyExitActivity",//退出活动

  },
  // 应用相关
  application: {
    GET_APPLICATION_LIST: '/supply/app/get', // 获取应用列表
    GET_APPLICATION_DETAIL_LIST: '/supply/app-module/module-list', // 应用对应的图片列表
  },

}

export default api;
