import {observable, action} from "mobx";
import {message} from 'antd'
import httpClient from "utils/httpClient";
import Cookies from "js-cookie";
import api from "config/api"

const {common} = api

class CommonStore {
  // 顶部菜单选中状态

  @observable topMenu = "connect";
  @observable checkAccountData = {telephone: ''};

  @observable commonLocationList = [];
  @observable expressList = [];

  @observable expressDetail = {
    expressInfo: [],
  }

  // 当前顶部选中的按钮的index
  @action
  setMenuKey = async key => {
    console.log(key)
    this.topMenu = key;
  };

  /**
   * 登陆
   * @param params
   * @returns {Promise<AxiosResponse<T>>}
   */
  @action
  async login(params) {
    const data = await httpClient.post(common.POST_LOGIN, {...params});
    if (data.success) {
      localStorage.setItem("token", data.data.authorityToken)
      localStorage.setItem("userInfo",JSON.stringify(data.data))
    }
    return data
  }

  /**
   * 忘记密码第一步
   * @param params
   * @returns {Promise<AxiosResponse<T>>}
   */
  @action
  async postCheckAccount(params) {
    const data = await httpClient.post(common.CHECK_ACCOUNT, {...params})
    if (data.success) {
      this.checkAccountData = data.data
      localStorage.setItem("forgetPwd", JSON.stringify(data.data))
    }
    return data
  }

  /**
   * 忘记密码第二步
   * @param params
   * @returns {Promise<AxiosResponse<T>>}
   */
  @action
  async postSetNewPassword(params) {
    const data = await httpClient.post(common.SET_NEW_PASSWORD, {...params});
    if (data.success) {

    }
    return data
  }


  /**
   * 退出登录
   * @returns {Promise<void>}
   */
  @action
  async logout() {
    await httpClient.post(common.POST_LOGOUT);
    localStorage.removeItem('token');
    localStorage.removeItem('query');
    localStorage.removeItem('freightArea');
    localStorage.removeItem('forgetPwd')
    window.location = '/login'
  }


  /**
   * 获取通用的三级地址
   * @param params
   * @returns {Promise<void>}
   */
  @action
  async postLocationByParent(params) {
    const data = await httpClient.post(common.POST_LOCATION_BY_PARENT, {...params})
    if (data.success) {
      let arr=data.data.map(item => ({
        parentId: item.parentCode,
        id: item.code,
        name: item.name,
        level: item.level
      }));
      this.commonLocationList = arr;
      return arr
    }
  }

  /**
   * 获取快递公司列表
   * @param params
   * @returns {Promise<void>}
   */
  @action
  getExpressList = async (params) => {
    const data = await httpClient.get(common.GET_EXPRESS_LIST, {params})
    if (data.success) {
      this.expressList = data.data
    }
  }

  /**
   * 获取物流详情
   * @param params
   * @returns {Promise<void>}
   */
  @action
  async getExpressDetail(params) {
    const data = await httpClient.get(common.GET_EXPRESS_DETAIL, {params})
    if (data.success) {
      if (!data.data.expressInfo) {
        data.data.expressInfo = []
      }
      this.expressDetail = data.data
    }
  }

  /**
   * 上传单个文件
   * @param params
   * @returns {Promise<{success}|any|AxiosResponse<T>>}
   */
  @action
  postUploadFile=async (params)=>{
    const data=await httpClient.post(common.POST_UPLOAD_FILE,params,{withCredentials: true})
    if(data.success){
      return data
    }
  }

  /**
   * 上传多个文件
   * @param params
   * @returns {Promise<{success}|any|AxiosResponse<T>>}
   */
  @action
  postUploadFiles=async (params)=>{
    const res=await httpClient.post(common.POST_UPLOAD_FILES,params,{withCredentials: true})
    if(res.success){
      return res.data
    }
  }
}

export default new CommonStore();
