import React, {Component} from "react";
import {Card, Form, Icon, Input, Button} from "antd";
import {inject, observer} from "mobx-react";
import {mainHost} from "../../config";

import styles from "./index.module.scss";

const FormItem = Form.Item;

@inject("commonStore")
@observer
@Form.create()
class ForgetInfo extends Component {
  constructor(props) {
    super();
    this.state = {
      picCodeSrc: '',
      showPicCode: false,
      getCodeText: "获取验证码",
      canGetCode: true,
    }
  }

  getCodeInterval = null;

  /**
   * 点击获取新的图形验证码
   * @author caohaojun
   * @param e
   */
  getPicCode = e => {
    this.setState({
      picCodeSrc: `${mainHost}/right/login/generateImageCode?${new Date().getTime()}`
    })
  }

  /**
   * 获取验证码
   */
  getCode = () => {
    if (this.state.canGetCode) {
      let count = 60;
      this.getCodeInterval = setInterval(() => {
        if (count === 0) {
          this.setState({
            getCodeText: "获取验证码",
            canGetCode: true,
          }, () => {
            this.getCodeInterval = null;
          })
        } else {
          this.setState({
            getCodeText: `${count}s后重新获取`,
            canGetCode: false,
          },()=>{
            count--;
          })
        }
      }, 1000)
    }
  }


  /**
   * 登录请求
   * @author caohaojun
   * @param e
   */
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        let data = await this.props.commonStore.postSetNewPassword({
          confirmPwd: values.password_again,
          pwd: values.password,
          smsCode: values.code,
          telephone: this.props.commonStore.checkAccountData.telephone,
          username: this.props.commonStore.checkAccountData.username
        })

        if (data.success) {
          localStorage.removeItem("forgetPwd")
          this.props.history.push({
            pathname: `/login`,
          })
        }
      }
    });
  };


  render() {
    const {getFieldDecorator} = this.props.form;
    let forgetPwd = JSON.parse(localStorage.getItem("forgetPwd"))
    return (<div className={styles.container}>
        <Card bordered={false}
              className={styles.login_header} bodyStyle={{padding: 0}}>
          <div className={styles.header_content}>
            <img src="../login_header.png" alt="" style={{height: 80}}/>
          </div>

        </Card>
        <Form onSubmit={this.handleSubmit}>
          <Card bordered={false}
                className={styles.forget_info_card}>
            <div className={styles.login_content}>

              <div className={styles.login_content_container}>
                <p className={styles.login_title}>找回密码</p>

                <div className={styles.login_content_line} style={{
                  marginBottom: "30px"
                }}>

                  <Input size="large"
                         prefix={<Icon type="tablet" style={{fontSize: 20, color: "#1890ff"}}/>}
                         readOnly={true}
                         value={(this.props.commonStore.checkAccountData.telephone || forgetPwd.telephone).replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')}/>

                </div>

                <div className={`${styles.login_content_line} ${styles.login_content_line_flex} `}>
                  <FormItem>
                    {getFieldDecorator('code', {
                      rules: [{required: true, message: '验证码不可为空'}],
                    })(
                      <Input prefix={<Icon type="safety-certificate" style={{fontSize: 20, color: "#1890ff"}}/>}
                             placeholder="请填写短信验证码"
                             size="large"
                             style={{width: 200}}/>,
                    )}
                  </FormItem>
                  <span className={`${styles.get_code} ${!this.state.canGetCode?styles.get_code_disable:''}`} onClick={this.getCode}>{this.state.getCodeText}</span>
                </div>
                <div className={styles.login_content_line}>
                  <FormItem> {getFieldDecorator('password', {
                    rules: [{required: true, message: '密码不可为空'}],
                  })(
                    <Input prefix={<Icon type="lock" style={{fontSize: 20, color: "#1890ff"}}/>} placeholder="请输入新密码"
                           size="large"/>,
                  )}</FormItem>

                </div>
                <div className={styles.login_content_line}>
                  <FormItem> {getFieldDecorator('password_again', {
                    rules: [{required: true, message: '请再次输入密码'}],
                  })(
                    <Input prefix={<Icon type="lock" style={{fontSize: 20, color: "#1890ff"}}/>} placeholder="再次输入新密码"
                           size="large"/>,
                  )}</FormItem>

                </div>


                <div className={styles.login_content_line}>
                  <Button type="primary" htmlType="submit" size="large" style={{width: "100%"}}>确定</Button>
                </div>
              </div>
            </div>
          </Card>
        </Form>

        <Card bordered={false}
              className={styles.login_footer}>
          <p className={styles.footer_content}>电信与信息服务业务经营许可证：浙ICP证 B2-20170751 | 浙江ICP备17028214号-2 |
            浙公网安备33010602008661</p>
        </Card>
      </div>
    );
  }
}

export default ForgetInfo;
