import {observable, action} from "mobx";
import {message} from "antd";
import httpClient from 'utils/httpClient'
import api from 'config/api'

const {order} = api


class OrderStore {
  @observable orderList = {
    list: [],
    page:1,
    pageSize:20,
    total:0
  }

  @observable orderDetail={
    goodsList:[],
    orderStatusList:[]
  }

  @observable parentOrderDetail={
    goodsList:[],
    orderStatusList:[]
  }

  @observable orderShipmentInfo={
    deliverGoodsList:[]
  }

  /**
   * 获取订单列表
   * @param params
   * @returns {Promise<void>}
   */
  @action
  async postOrderList(params) {
    const data = await httpClient.post(order.POST_ORDER_LIST, {...params})
    if (data.success) {
      this.orderList = data.data
    }
  }

  /**
   * 获取子订单详情
   * @param params
   * @returns {Promise<void>}
   */
  @action
  async getOrderDetail(params){
    const data=await httpClient.get(order.GET_ORDER_DETAIL,{params})
    if (data.success) {
      this.orderDetail = data.data
    }
  }
  /**
   * 获取父订单详情
   * @param params
   * @returns {Promise<void>}
   */
  @action
  async getParentOrderDetail(params){
    const data=await httpClient.get(order.GET_PARENT_ORDER_DETAIL,{params})
    if (data.success) {
      this.orderDetail = data.data
    }
  }

  /**
   * 获取发货弹窗数据信息
   * @param params
   * @returns {Promise<void>}
   */
  @action
  async getOrderShipmentInfo(params){
    const data=await httpClient.get(order.GET_ORDER_SHIPMENT_INFO,{params})
    if (data.success) {
      this.orderShipmentInfo = data.data
    }
  }

  @action
  async patchShipmentGoods(params){
    const data=await httpClient.post(order.POST_SHIPMENT_GOODS,{...params})
    return data
  }

}

export default new OrderStore();
