import {observable, action} from "mobx/lib/mobx";
import httpClient from "utils/httpClient"
import api from 'config/api'

const {goods} = api

class JdStore {
  @observable jdCacheStockArea = [{name: "杭州", code: "15_1213_0_0"}];
  @observable jdCategoryList = [];
  @observable jdLocationList = [];
  @observable jdGoodsList = {
    list: [],
    page: 0,
    pageSize: 20,
    total: 0
  };
  @observable jdGoodsDetail = {
    imageUrl: []
  };

  @observable goodsMarketPrice = '';
  @observable jdProductInfo = {}

  /**
   * 缓存库查询城市
   * @param params
   * @returns {Promise<void>}
   */
  @action
  async getJDCacheStockArea(params) {
    const {data} = await httpClient.get(goods.GET_JD_CACHE_STOCK_AREA, {params})
    if (data) this.jdCacheStockArea = data
    // console.log(data)
  }

  /**
   * 查询京东待挑选商品信息
   * @param params
   * @returns {Promise<void>}
   */
  @action
  async getJDProductList(params) {
    let data = await httpClient.get(goods.GET_JD_PRODUCT_LIST, {params});

    if (data.success) {
      if (data.data.pageSize * 999 < data.data.total) {
        data.data.total = data.data.pageSize * 999
      }
      this.jdGoodsList = data.data
    }
  }

  /**
   * 京东分类查询
   * @param params
   * @returns {Promise<void>}
   */
  @action
  async getJDCategoryList(params) {
    const {data} = await httpClient.get(goods.GET_JD_CATEGORY_LIST, {params});
    if (data) this.jdCategoryList = data.map(item => ({
      parentId: item.parentId,
      id: item.catId,
      name: item.name,
      level: item.level
    }))
  }

  /**
   * 京东地区查询
   * @param params
   * @returns {Promise<void>}
   */
  @action
  async getJDAreaList(params) {
    const {data} = await httpClient.get(goods.GET_JD_AREA_LIST, {params});
    if (data) this.jdLocationList = data.map(item => ({
      parentId: item.parentCode,
      id: item.code,
      name: item.name,
      level: item.level || 0
    }))
  }

  /**
   * 京东商品详情查询
   * @param params
   * @returns {Promise<void>}
   */
  @action
  async getJDProductDetail(params) {
    const {data} = await httpClient.get(goods.GET_JD_PRODUCT_DETAIL, {params});
    if (data) this.jdGoodsDetail = data
  }

  /**
   * 实时爬取京东商品市场价
   * @param params
   * @returns {Promise<void>}
   */
  @action
  async getJDMarketPrice(params) {
    const {data} = await httpClient.get(goods.GET_JD_MARKET_PRICE, {params})
    if (data) this.goodsMarketPrice = data;
  }

  /**
   * 获取京东商品信息（市场价，税率）
   * @param params
   * @returns {Promise<void>}
   */
  @action
  async getJDProductInfo(params) {
    const {data} = await httpClient.get(goods.GET_JD_PRODUCT_INFO, {params})
    if (data) this.jdProductInfo = data
  }

  /**
   * 挑选京东商品
   * @param params
   * @returns {Promise<void>}
   */
  @action
  async postJDPickGoods(params) {
    const data = await httpClient.post(goods.POST_JD_PICK_GOODS, {...params})
    return data

  }

}

const jdStore = new JdStore();
export default jdStore;

