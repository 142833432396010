import {observable, action} from "mobx";
import {message} from "antd";
import httpClient from 'utils/httpClient';
import {formatArea} from 'utils/tools'
import {area} from "mock/data"
import api from "config/api"

const {freight} = api;

class FreightStore {
  @observable simpleFreightTemps = [];
  @observable freightList = [];
  @observable freightDetail = {
    rules: []
  }

  @observable freightGoodsList = {
    list: [],
    page: 1,
    pageSize: 20,
    total: 0
  }

  /**
   * 获取运费模板区域列表
   * @param params
   * @returns {Promise<void>}
   */
  @action
  getFreightArea = async (params) => {
    const data = await httpClient.get(freight.GET_FREIGHT_AREA, {params})
    if (data.success) {
      localStorage.setItem("freightArea", JSON.stringify(formatArea(data.data)))
    }
  }

  /**
   * 查找运费模板名称是否重复
   * @param params
   * @returns {Promise<void>}
   */
  @action
  getIsRepeatName = async(params) => {
    const {data} = await httpClient.get(freight.GET_IS_REPEAT_NAME, {params})
  }

  /**
   * 通过id获取运费模板详情
   * @param params
   * @returns {Promise<void>}
   */
  @action
  getFreightDetail = async(params) => {
    const data = await httpClient.get(freight.GET_FREIGHT_DETAIL, {params})
    if (data.success) {
      this.freightDetail = data.data
    }
  }

  /**
   * 查找所有运费模板
   * @param params
   * @returns {Promise<void>}
   */
  @action
  getFreightTemps = async(params) => {
    const data = await httpClient.get(freight.GET_FREIGHT_TEMPS, {params})
    if (data.success) {
      this.freightList = data.data
    }
  }

  /**
   * 查找所有运费模板(select用)
   * @param params
   * @returns {Promise<void>}
   */
  @action
  getSimpleFreightTemps = async(params) => {
    const data = await httpClient.get(freight.GET_SIMPLE_FREIGHT_TEMPS, {params})
    if (data.success)
      this.simpleFreightTemps = data.data;
  }


  /**
   * 保存运费模板
   * @param params
   * @returns {Promise<void>}
   */
  @action
  postSaveFreightTemp = async(params) => {
    const data = await httpClient.post(freight.POST_SAVE_FREIGHT_TEMP, {...params})
    return data;
  }

  /**
   * 更新运费模板
   * @param params
   * @returns {Promise<void>}
   */
  @action
  postUpdateFreightTemp = async(params) => {
    const data = await httpClient.post(freight.POST_UPDATE_FREIGHT_TEMP, {...params});
    console.log(data)
    return data;
  }

  /**
   * 删除运费模板
   * @param params
   * @returns {Promise<void>}
   */
  @action
  deleteFreightTemp = async(params) => {
    const {data} = await httpClient.delete(freight.DELETE_FREIGHT_TEMP + '/' + params.id)
  }

  /**
   * 运费模板商品列表
   * @param params
   * @returns {Promise<void>}
   */
  @action
  postFreightGoodsList = async(params) => {
    const data = await httpClient.post(freight.POST_FREIGHT_GOODS_LIST, {...params});
    if (data.success) {
      this.freightGoodsList = data.data;
    }
  }

  /**
   * 商品运费模板变更
   * @param params
   * @returns {Promise<void>}
   */
  @action
  postChangeFreightTemp=async params=>{
    const data=await httpClient.post(freight.POST_CHANGE_FREIGHT_TEMP,{...params})
    return data
  }

}

export default new FreightStore();
