import moment from 'moment'
import {routers} from "../config/route"
import qs from 'qs'

/**
 * 返回地址对应的对象
 * @param url
 */
export function urlParseQuery(url) {
  var obj = {}, search;
  obj.get = function (key) {
    return this[key];
  };
  obj.set = function (key, value) {
    this[key] = value;
  };
  obj.delete = function (key) {
    delete this[key];
  };
  if (url) {
    search = url.split('?')[1];
  } else {
    url = window.location;
    search = url.search.split('?')[1];
  }
  if (search) {
    let arr = search.split('&');
    for (let i in arr) {
      let entry = arr[i].split('=');
      obj[entry[0]] = entry[1];
    }
  }
  return obj;
};


/**
 * 每个页面页头标题获取
 * @param path
 * @returns {*|string}
 */
export function getTitle(path) {
  let title;
  for (let i = 0; i < routers.length; i++) {
    let router = routers[i];
    if (path === router.route) {
      title = router.notShowTitle ? "" : router.title || router.name;
      break;
    } else if (router.children && router.children.length) {
      for (let j = 0; j < router.children.length; j++) {
        let child = router.children[j];
        if (path === child.route) {
          title = child.notShowTitle ? "" : child.title || child.name;
          break;
        }
      }
    }
  }
  return title;
}

/**
 * 递归格式化部门树
 * @param data
 * @returns {Array}
 */
export function formatDepartmentTree(data) {
  let tree = [];

  let getChildren = function (data, tree) {
    tree = data.map((item, index, arr) => {
      let obj = {}
      let flag = item.childNodes && item.childNodes.length;
      Object.assign(obj, {
        children: item.childNodes || [],
        title: item.name,
        value: item.id,
        key: item.id,
        isLeaf: !flag,

      });
      if (flag) {
        getChildren(item.childNodes, obj.children)
      }
      return obj
    })
  };
  getChildren(data, tree);
  return tree
}

/**
 * 运费模板区域列表格式化
 * @param data
 * @returns {*}
 */
export function formatArea(data) {
  return data.map(item => {
    let arr = null;
    if (item.children) {
      arr = formatArea(item.children)
    }
    return {
      id: item.id,
      parentId: item.parentId,
      dictKey: item.dictKey,
      dictValue: item.dictValue,
      children: arr,
      check: false,
      freightIndex: 0
    }
  })
}

/**
 * 图片上传处理图片转base64方法
 * @param file
 * @returns {Promise<any>}
 */
export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error)
  })
}

/**
 *
 * @param arr
 * @returns {*|Array|*|(function(*, *): Array)}
 */
export function cartesianProduct(array) {
  if (array.length < 2) return array[0] || [];
  return [].reduce.call(array, function (col, set) {
    let res = [];
    col.forEach(function (c) {
      set.forEach(function (s) {
        let t = [].concat(Array.isArray(c) ? c : [c]);
        t.push(s);
        res.push(t);
      })
    });
    return res;
  });
}

/**
 * 金额显示格式化
 * @param data
 * @returns {string}
 */
export function showMoney(data) {
  return (data / 100).toFixed(2)
}

/**
 * 格式化时间
 * @param data
 * @returns {string}
 */
export function formatTime(data) {
  return data ? moment(data).format("YYYY-MM-DD HH:mm:ss") : ''
}

export function addKey(data) {
  data.list = data.list.map((item, index) => Object.assign(item, {key: index}))
  return data
}

export function ObjectEquals(a, b) {
  var aProps = Object.getOwnPropertyNames(a);
  var bProps = Object.getOwnPropertyNames(b);
  if (aProps.length != bProps.length) {
    return false;
  }
  for (var i = 0; i < aProps.length; i++) {
    var propName = aProps[i]

    var propA = a[propName]
    var propB = b[propName]
    if ((typeof (propA) === 'object')) {
      if (this.isObjectValueEqual(propA, propB)) {
        // return true     这里不能return ,后面的对象还没判断
      } else {
        return false
      }
    } else if (propA !== propB) {
      return false
    }
  }
  return true
}

export function getQuery() {
  return qs.parse(window.location.search.substring(1));
}

