import {observable, action} from "mobx";

class GoodsLocalStore{
  @observable modifyReason=""

  @action
  setModifyReason=async (reason)=>{
    this.modifyReason=reason;
    localStorage.setItem("modifyReason",reason)
  }
}

export default new GoodsLocalStore();
