import {observable, action} from "mobx";
import httpClient from 'utils/httpClient';
import api from 'config/api'
import {message} from "antd";
import {addKey} from "utils/tools";

const {goods} = api

class ResourceStore {
  @observable resourceProductInfo = {}
  @observable resourceProductBasicInfo = {}


  /**
   * 获取资源库列表
   * @param params
   * @returns {Promise<void>}
   */
  @action
  getResourceList = async params => {
    const res = await httpClient.post(goods.POST_GET_RESOURCE_LIST, params)
    if (res.success) {
      return addKey(res.data)
    }
  }

  /**
   * 获取资源库产品详情
   * @param params
   * @returns {Promise<void>}
   */
  @action
  getResourceProductInfo = async params => {
    const res = await httpClient.post(goods.POST_GET_RESOURCE_PRODUCT_INFO, params)
    if (res.success) {
      this.resourceProductInfo = res.data
    }
  }

  /**
   * 获取资源库产品基本详情
   * @param params
   * @returns {Promise<void>}
   */
  @action
  getResourceProductBasicInfo = async params => {
    const res = await httpClient.post(goods.POST_GET_RESOURCE_PRODUCT_BASIC_INFO, params)
    if (res.success) {
      this.resourceProductBasicInfo = res.data
    }
  }

  /**
   * 编辑资源库产品基本详情
   * @param params
   * @returns {Promise<void>}
   */
  @action
  postEditResourceProductBasicInfo = async params => {
    const res = await httpClient.post(goods.POST_EDIT_RESOURCE_PRODUCT_BASIC_INFO, params)
    return res
  }

  /**
   * 上架资源库商品
   * @param params
   * @returns {Promise<AxiosResponse<any>>}
   */
  @action
  postUpShelfResourceProduct = async params => {
    const res = await httpClient.post(goods.POST_UP_SHELF_RESOURCE_PRODUCT, params)
    return res
  }

}

export default new ResourceStore();
