import React, {Component} from "react";
import {Card, Form, Icon, Input, Button} from "antd";
import {inject, observer} from "mobx-react";

import styles from "./index.module.scss";

const FormItem = Form.Item;

@inject("commonStore")
@observer
@Form.create()
class ForgetAccount extends Component {
  constructor(props) {
    super();
    this.state = {}
  }

  /**
   * 登录请求
   * @author caohaojun
   * @param e
   */
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        let data = await this.props.commonStore.postCheckAccount({
          userName: values.account
        })
        if (data.success) {
          this.props.history.push({
            pathname: `/forget/info`,
          })
        }
      }
    });
  };


  render() {
    const {getFieldDecorator} = this.props.form;
    return (
      <div className={styles.container}>
        <Card bordered={false}
              className={styles.login_header} bodyStyle={{padding: 0}}>
          <div className={styles.header_content}>
            <img src="../login_header.png" alt="" style={{height: 80}}/>
          </div>

        </Card>
        <Form onSubmit={this.handleSubmit}>
          <Card bordered={false}
                className={styles.forget_card}>
            <div className={styles.login_content}>

              <div className={styles.login_content_container}>
                <p className={styles.login_title}>找回密码</p>
                <p className={styles.forget_account_tip}>请输入你需要找回登录密码的账户名</p>

                <div className={`${styles.login_content_line} ${styles.forget_account_container}`}>
                  <FormItem>
                    {getFieldDecorator('account', {
                      rules: [{required: true, message: '请输入账号'}],
                    })(
                      <Input prefix={<Icon type="tablet" style={{fontSize: 20, color: "#1890ff"}}/>} placeholder="请输入登录账号" size="large"/>,
                    )}
                  </FormItem>
                </div>
                <div className={styles.login_content_line}>
                  <Button type="primary" htmlType="submit" size="large" style={{width: "100%"}}>确定</Button>
                </div>
              </div>
            </div>
          </Card>
        </Form>
        <Card bordered={false}
              className={styles.login_footer}>
          <p className={styles.footer_content}>电信与信息服务业务经营许可证：浙ICP证 B2-20170751 | 浙江ICP备17028214号-2 |
            浙公网安备33010602008661</p>
        </Card>
      </div>

    );
  }
}

export default ForgetAccount;
