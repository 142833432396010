const ENV = process.env.REACT_APP_API_ENV;
const apiHost = {
    dev: "http://dev-gateway.fxqifu.net/pangu",
    test:"https://qa-gateway.fxqifu.net/pangu",
    prod: "https://gateway.fxqifu.net/pangu"
};

export const mainHost = apiHost[ENV];
export const DATA_CODE = {
    SUCCESS: 2000,
    QUERY_PARAMS_ERROR: 3000,
    LOGIN_ERROR: "100003",
    OTHER_ERROR: 5000,
    GOODS_CHANGE_PERMIT_BY_ACTIVITY:'50000407'
};
