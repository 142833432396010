export const routers = [
  {
    id: 1,
    name: "首页",
    key: "home",
    route: "/home",
    icon: "home"
  }, {
    id: 2,
    key: "goods",
    name: "商品管理",
    icon: "home",
    children: [
      {
        id: 21,
        name: "发布商品",
        key: "publish_goods",
        route: "/goods/publish",
      }, {
        id: 22,
        name: "商品库",
        key: "goods_warehouse",
        route: "/goods/warehouse",
      }, {
        id: 23,
        name: "商品变更申请记录",
        key: "update_apply",
        route: "/goods/update-apply",
      }, {
        id: 24,
        name: "京东接口管理",
        key: "jd_api",
        route: "/goods/jd-api",
      }, {
        id: 25,
        name: "资源库",
        key: "resource",
        route: "/goods/resource",
      },
    ]
  }, {
    id: 3,
    key: "order",
    name: "订单管理",
    icon: "home",
    children: [
      {
        id: 31,
        name: "采购订单管理",
        key: "order_manage",
        route: "/order/manage",
      }
    ]
  }, {
    id: 4,
    key: "finance",
    name: "财务管理",
    icon: "home",
    children: [
      {
        id: 41,
        name: "财务概览",
        key: "finance_overview",
        route: "/finance/overview",
      }, {
        id: 42,
        name: "交易明细",
        key: "deal_detail",
        route: "/finance/deal-detail",
      }, {
        id: 43,
        name: "结算账户",
        key: "settlement_account",
        route: "/finance/settlement-account",
      },
    ]
  }, {
    id: 7,
    key: "activity",
    name: "活动管理",
    icon: "home",
    children: [
      {
        id: 71,
        name: "派样活动",
        key: "faction",
        route: "/activity/faction",
      },
    ]
  }, {
    id: 5,
    key: "market",
    name: "应用市场",
    icon: "home",
    route: "/market",
  }, {
    id: 6,
    key: "setting",
    name: "设置",
    icon: "home",
    children: [
      {
        id: 61,
        name: "运费模板",
        key: "freight_template",
        route: "/setting/freight-template",
      },
    ]
  },
];
