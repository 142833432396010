// @flow
import React, {Component} from "react";
import {Card, Form, Icon, Input, Button} from "antd";
import {inject, observer} from "mobx-react";
import {mainHost} from "../../config";
import MD5 from 'md5';

import styles from "./index.module.scss";

const FormItem = Form.Item;

@inject("commonStore")
@observer
@Form.create()
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      picCodeSrc: '',
      showPicCode: false
    }
  }

  /**
   * 点击获取新的图形验证码
   * @author caohaojun
   * @param e
   */
  getPicCode = e => {
    this.setState({
      // picCodeSrc:`https://iph.href.lu/${parseInt(100*Math.random())||80}x${parseInt(100* Math.random())||40}`
      picCodeSrc: `${mainHost}/right/login/generateImageCode?${new Date().getTime()}`
    })
  }

  /**
   * 登录请求
   * @author caohaojun
   * @param e
   */
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        let data = await this.props.commonStore.login({
          customerType: 1,
          userName: values.username,
          password: MD5(values.password)
        })
        if (data.success) {
          this.props.history.push({
            pathname: '/'
          })
        }
      }
    });
  };

  /**
   * 忘记密码
   */
  goToForget = () => {
    this.props.history.push({
      pathname: '/forget/account'
    })
  }

  render() {
    const {getFieldDecorator} = this.props.form;

    return (<div className={styles.container}>
        <Card bordered={false}
              className={styles.login_header} bodyStyle={{padding: 0}}>
          <div className={styles.header_content}>
            <img src="./login_header.png" alt="" style={{height: 80}}/>
          </div>

        </Card>
        <Form onSubmit={this.handleSubmit}>
          <Card bordered={false}
                className={styles.login_card}>
            <div className={styles.login_content}>
              <div className={styles.login_img_container}>
                <img src="./login_welcome.png" style={{width: 500}}/>
              </div>
              <div className={styles.login_content_container}>
                <p className={styles.login_title}>欢迎登录</p>
                <div className={styles.login_content_line}>
                  <FormItem>
                    {getFieldDecorator('username', {
                      rules: [{required: true, message: '请输入账号'}],
                    })(
                      <Input prefix={<Icon type="tablet" style={{fontSize: 20, color: "#1890ff"}}/>}
                             placeholder="请输入账号" size="large"/>,
                    )}
                  </FormItem>

                </div>
                <div className={styles.login_content_line}>
                  <FormItem>
                    {getFieldDecorator('password', {
                      rules: [{required: true, message: '请输入密码'}],
                    })(
                      <Input prefix={<Icon type="lock" style={{fontSize: 20, color: "#1890ff"}}/>} placeholder="请输入密码"
                             type="password" size="large"/>,
                    )}
                  </FormItem>
                </div>
                <div
                  className={`${styles.login_content_line} ${styles.login_content_line_right} ${styles.login_content_line_forget_pwd}`}>
                  <span className={styles.table_action_btn} onClick={this.goToForget}>忘记密码</span>
                </div>
                <div className={styles.login_content_line}>
                  <Button type="primary" size="large" style={{width: "100%"}} htmlType="submit">登录</Button>
                </div>
              </div>
            </div>
          </Card>
        </Form>
        <Card bordered={false}
              className={styles.login_footer}>
          <p className={styles.footer_content}>电信与信息服务业务经营许可证：浙ICP证 B2-20170751 | 浙江ICP备17028214号-2 |
            浙公网安备33010602008661</p>
        </Card>
      </div>
    );
  }
}

export default Login;
