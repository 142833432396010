import axios from "axios";
import {notification} from "antd";
import {mainHost, DATA_CODE} from "../config";
import Cookies from "js-cookie";
import qs from "qs";


const downLoadMap = [
  '/supply/marketing/activity/sample/export'
]
const service = axios.create({
  baseURL: mainHost,
  timeout: 10000
});

service.interceptors.request.use(
  config => {
    config.headers["Content-Type"] = "application/json;charset=UTF-8"
    config.headers["authority_token"] = localStorage.getItem("token");
    // if (config.method === "post") {
    //     config.data = qs.stringify(config.data);
    // }
    return config;
  },
  err => {
    notification.error({
      title: "发送请求失败",
      message: err
    });
    return false;
  }
);
service.interceptors.response.use(
  res => {
    const {status, data, statusText, config} = res;
    if (status < 200 || status > 300) {
      notification.error({
        message: `请求错误: ${status}`,
        description: statusText
      });
    } else if (downLoadMap.includes(config.url)) {
      return data
    } else if (!data.success) {
      if(data.code=== DATA_CODE.GOODS_CHANGE_PERMIT_BY_ACTIVITY){

      }else{
        notification.error({
          message: "提示",
          description: data.message
        });
        if (data.code === DATA_CODE.LOGIN_ERROR)
          window.location.href = "/login";
      }
      return data
    } else {
      return data || true;
    }
    return false;
  },
  err => {
    console.log(err)
    // const { status, statusText } = err.response;

    notification.error({
      title: "提示",
      message: "系统错误"
    });
    return false;
  }
);

export default service;
