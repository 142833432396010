import React from "react";
import zhCN from "antd/lib/locale-provider/zh_CN";
import {ConfigProvider} from "antd";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Loadable from "react-loadable";
import {Provider} from "mobx-react";

// components
import Login from "views/Login";
import ForgetAccount from 'views/Login/forget_account'
import ForgetInfo from 'views/Login/forget_info'
import Loading from "components/Loading";
import "./App.scss";

// stores
import commonStore from "stores/common";
import jdStore from 'stores/goods/jd';
import goodsJdNewStore from 'stores/goods/jdNew'
import supplierStore from 'stores/goods/supplier'
import resourceStore from 'stores/goods/resource'
import goodsLocalStore from 'stores/goods/local'
import freightStore from 'stores/setting/freight'
import orderStore from 'stores/order'
import financeStore from 'stores/finance'

import factionStore from 'stores/activity/faction'

const stores = {
  commonStore,
  jdStore,
  goodsJdNewStore,
  supplierStore,
  resourceStore,
  goodsLocalStore,
  freightStore,
  orderStore,
  financeStore,
  factionStore
};

function App() {
  return (
    <Provider {...stores}>
      <ConfigProvider locale={zhCN}>
        <Router>
          <Switch className="c-body-wrap">
            <Route path="/login" exact component={Login}/>
            <Route path="/forget/account" exact component={ForgetAccount}/>
            <Route path="/forget/info" exact component={ForgetInfo}/>
            <Route
              path="/"
              component={Loadable({
                loader: () => import("./views/Main"),
                loading: Loading
              })}
            />
          </Switch>
        </Router>
      </ConfigProvider>
    </Provider>
  );
}

export default App;
