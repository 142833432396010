import {observable, action} from "mobx";
import httpClient from 'utils/httpClient';
import api from 'config/api'
import {message} from "antd";
import {addKey} from "utils/tools";

const {activity} = api

class FactionStore {

  /**
   * 获取报名中列表
   * @param params
   * @returns {Promise<void>}
   */
  @action
  getSignUpList = async params => {
    const res = await httpClient.post(activity.POST_GET_SIGN_UP_LIST, params)
    if (res.success) {
      return addKey(res.data)
    }
  }

  /**
   * 获取报名记录列表
   * @param params
   * @returns {Promise<void>}
   */
  @action
  getSignUpRecordList = async params => {
    const res = await httpClient.get(activity.GET_SIGN_UP_RECORD_LIST, {params})
    if (res.success) {
      return addKey(res.data)
    }
  }

  /**
   * 用户资料列表
   * @param params
   * @returns {Promise<void>}
   */
  @action
  getUserInfoList = async params => {
    const res = await httpClient.post(activity.POST_GET_USER_INFO_LIST, params)
    if (res.success) {
      return addKey(res.data)
    }
  }

  /**
   * 获取派样活动详情
   * @param params
   * @returns {Promise<T>}
   */
  @action
  getActivityInfo = async params => {
    const res = await httpClient.get(activity.GET_ACTIVITY_INFO, {params});
    if (res.success) {
      return res.data
    }
  }

  /**
   * 提报活动
   * @param params
   * @returns {Promise<void>}
   */
  @action
  postPresentActivity = async params => {
    const res = await httpClient.post(activity.POST_PRESENT_ACTIVITY, params)
    return res
  }

  /**
   * 获取派样活动详情
   * @param params
   * @returns {Promise<T>}
   */
  @action
  downloadUserInfo = async () => {
    return await httpClient.get(activity.GET_DOWNLOAD_USER_INFO, {responseType: 'blob'});
  }

  @action
  exitActivity = async params => {
    const res = await httpClient.post(activity.POST_EXIT_ACTIVITY, params)
    return res;
  }

}

export default new FactionStore();
